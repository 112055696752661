@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    a {
        @apply text-blue-900 hover:text-blue-500;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
